window.angular.module('MyHippoProducer.Components').component('tooltip', {
    bindings: {
        text: '<',
        trigger: '@',
        animation: '@',
        closeDelay: '<',
    },
    templateUrl: 'components/tooltip/tooltip.html',
    controllerAs: 'ctrl',
    controller: function () {
        this.$onInit = () => {
            // Set Defaults
            this.closeDelay = this.closeDelay || 300;
            this.trigger = this.trigger || 'mouseenter';
            this.animation = this.animation || 'fade';
        };
    }
});
